<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img
        slot="logo"
        src="../assets/images/logo/logo-light.png"
        class="logo-light"
      />
      <img
        slot="logo-dark"
        src="../assets/images/logo/logo-dark.png"
        class="logo-dark"
      />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="rn-slider-area">
      <div
        class="slide slide-style-2 slider-paralax d-flex align-center justify-center bg_image bg_image--32"
        data-black-overlay="4"
      >
        <Banner>
          <h1 slot="heading-title" class="heading-title home-hero-heading">
            Flooring <br> that changes everything.
          </h1>
        </Banner>
      </div>
    </div>
    <!-- End Slider Area  -->

    <!-- Start Service Area  -->
    <div class="rn-service-area rn-section-gap bg_color--1" id="services">
      <v-container>
        <v-row>
          <v-col lg="8">
            <div class="feature-area">
              <h2>
                Flooring Professionals
              </h2>
              <h3 class="heading-title mt--20 fontWeight500 lineheight--1-8">
                At Leonov Flooring we know how much a good-looking floor matters. We can to help you transform your home with our professional installation services.
              </h3>
            </div>
          </v-col>
        </v-row>
        <Service class="interior-services" />
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Pricing Plan Area  -->
    <div class="rn-pricing-plan-area bg_color--1 mb--30">
      <v-container>
        <PricingPlan />
      </v-container>
    </div>
    <!-- End Pricing Plan Area  -->
    
    <!-- Start Gallery Area  -->
    <div class="rn-gallery-area rn-section-gap bg_color--1 position-relative" id="gallery">
      <div class="section-title text-center pb--50">
        <h2>Gallery</h2>
        <p>Check out some of our recent projects.</p>
      </div>
      <div class="rn-masonary-wrapper">
        <div class="wrapper plr--70 plr_sm--30 plr_md--30">
          <div class="be-custom-gallery">
            <Portfolio />
          </div>
        </div>
      </div>
    </div>
    <!-- End Gallery Area  -->

    <div
      class="rn-counterup-area pt_sm--50 pt_md--50 pb--100 bg_color--1"
    >
      <v-container>
        <Counter />
      </v-container>
    </div>

    <!-- Start Contact Area  -->
    <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../assets/images/about/about-6.jpg"
            />
          </Contact>
        </v-container>
      </div>
    </div>
  <!-- End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
import Header from "../components/header/HeaderOnePageThree";
import Banner from "../components/slider/Banner";
import PricingPlan from "../components/pricing-plan/PricingPlan";
import Counter from "../components/counter/CounterOne"
import Service from "../components/service/ServiceSix";
import Portfolio from "../components/portfolio/PortfolioThree";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/FooterTwo";

export default {
  components: {
    Header,
    Banner,
    PricingPlan,
    Counter,
    Service,
    Portfolio,
    Contact,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style>
@media only screen and (min-width: 1264px) {
  .home-hero-heading {
    font-size: 90px !important;
  }
}
</style>