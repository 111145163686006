<template>
  <v-row align="start" class="row--35">
    <v-col cols="12" order="2" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Get in Touch</h2>
        <p class="description">
          If you want to get a quote or just ask us a question, feel free to text us or fill out the form below.
        </p>
      </div>
      <v-row fluid>
        <v-col cols="12" md="6">
          <div class="rn-address mb--30 mb_sm--0 mb_md--0">
            <div class="inner">
              <v-row fluid>
                <v-col cols="3">
                  <div class="icon">
                    <i class="fas fa-phone"></i>
                  </div>
                </v-col>
                <v-col cols="9">
                  <h4 class="phone-number"><a href="tel:+9419148487">(941) 914-8487</a></h4>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="rn-address mb--30">
            <div class="inner">
              <v-row fluid>
                <v-col cols="3">
                  <div class="icon">
                    <i class="fas fa-phone"></i>
                  </div>
                </v-col>
                <v-col cols="9">
                  <h4 class="phone-number"><a href="tel:+19412765377">(941) 276-5377</a></h4>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="form-wrapper" v-if="!submitted && !submitError">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Your Name *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Your email *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Write a Subject"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea
                  v-model="formData.message"
                  placeholder="Your Message"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
      <div v-else-if="submitted && !submitError">
        Thank you for your message! We'll be in touch soon!
      </div>
      <div v-else>
        Error sending your message. Please try again later or email us directly at <a href="mailto:vladimirleonov68@gmail.com">vladimirleonov68@gmail.com</a>!
      </div>
    </v-col>    
  </v-row>
</template>

<script>
  import axios from 'axios';
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        submitButton: "Submit", 
        submitted: false,
        submitError: false,
        formData: {
          name: "",
          email: "",
          subject: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        this.submitButton = "Submitting...";
        axios.post('https://us-central1-leonovflooring.cloudfunctions.net/sendEmail', { name: this.formData.name, email: this.formData.email, phone: this.formData.phone, message: this.formData.message })
        .then(() => {
          this.submitted = true;
        })
        .finally(() => {
          this.$gtag.event('conversion', {'send_to': 'AW-10855348841/8TZECP-j-6EDEOn0nbgo'})
        })
        .catch((error) => {
          this.submitError = true;
          console.log(error);
        });
      },
    },
  };
</script>
<style scoped>
.icon {
  position: inherit !important;
  left: 0 !important;
  top: 0 !important;
}
.phone-number {
  padding-top: 25px;
}
</style>