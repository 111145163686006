<template>
  <v-row class="service-one-wrapper mt--30">
    <!-- Start Single Service  -->
    <v-col
      xl="4"
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--1">
        <div class="icon">
          <div class="icon pb--30" v-html="iconSvg(item.icon)"></div>
        </div>
        <div class="content">
          <h3 class="heading-title">
            {{ item.title }}
          </h3>
          <p>
            {{ item.desc }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";

  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "layers",
            title: "Variety of Floor Types",
            desc: `We work with all kinds of floors, except carpet. Whether you're looking for a tile, wood or laminate floor - we got you covered.`,
          },
          {
            icon: "users",
            title: "Experienced Installers",
            desc: `Our installers are industry professionals with over 10 years of experience, covering over 50,000 sqft every year!`,
          },
          {
            icon: "pen-tool",
            title: "Design Advice",
            desc: `Choosing your perfect flooring is not as easy task. We can guide you on your path to the perfect floor.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
