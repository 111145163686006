<template>
  <div>
    <v-tabs v-model="tab" centered flat hide-slider color="primary">
      <v-tab :ripple="false" v-for="item in tabItems" :key="item.id">{{
        item.name
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabContent" :key="item.id">
        <CoolLightBox
          :items="item.content"
          :index="index"
          :fullScreen="true"
          :effect="'fade'"
          @close="index = null"
        >
        </CoolLightBox>
        <div
          class="gallery-wrapper gallery-grid mesonry-list grid-metro3"
          id="animated-thumbnials"
        >
          <div
            v-for="(item, i) in item.content"
            :key="i"
            @click="index = i"
            class="image masonry_item portfolio-six-col"
          >
            <div class="item-portfolio-static ">
              <div class="portfolio-static">
                <div class="thumbnail-inner">
                  <div class="thumbnail">
                    <img :src="item.thumb" alt="portfolio image" />
                  </div>
                </div>

                <div class="content">
                  <div class="inner">
                    <p>{{ item.tag }}</p>
                    <!-- <h4>{{ item.title }}</h4> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tab: null,
        index: null,
        tabItems: [
          {
            id: 1,
            name: "All Types",
          },
          {
            id: 2,
            name: "Showers",
          },
          {
            id: 3,
            name: "Tile",
          },
          {
            id: 4,
            name: "Vinyl/Laminate",
          },
          {
            id: 5,
            name: "Backsplash",
          },
        ],
        tabContent: [
          {
            id: 1,
            content: [
              {
                thumb: require("../../assets/images/portfolio/4.jpg"),
                src: require("../../assets/images/portfolio/4.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/5.jpg"),
                src: require("../../assets/images/portfolio/5.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/6.jpg"),
                src: require("../../assets/images/portfolio/6.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/7.jpg"),
                src: require("../../assets/images/portfolio/7.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/8.jpg"),
                src: require("../../assets/images/portfolio/8.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/9.jpg"),
                src: require("../../assets/images/portfolio/9.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/10.jpg"),
                src: require("../../assets/images/portfolio/10.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/11.jpg"),
                src: require("../../assets/images/portfolio/11.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/12.jpg"),
                src: require("../../assets/images/portfolio/12.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/13.jpg"),
                src: require("../../assets/images/portfolio/13.jpg"),
                tag: "Tile",
              },
              // {
              //   thumb: require("../../assets/images/portfolio/14.jpg"),
              //   src: require("../../assets/images/portfolio/14.jpg"),
              //   tag: "Tile",
              // },
              {
                thumb: require("../../assets/images/portfolio/16.jpg"),
                src: require("../../assets/images/portfolio/16.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/17.jpg"),
                src: require("../../assets/images/portfolio/17.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/15.jpg"),
                src: require("../../assets/images/portfolio/15.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/25.jpg"),
                src: require("../../assets/images/portfolio/25.jpg"),
                tag: "Vinyl",
              },              
              {
                thumb: require("../../assets/images/portfolio/18.jpg"),
                src: require("../../assets/images/portfolio/18.jpg"),
                tag: "Tile + Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/29.jpg"),
                src: require("../../assets/images/portfolio/29.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/19.jpg"),
                src: require("../../assets/images/portfolio/19.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/20.jpg"),
                src: require("../../assets/images/portfolio/20.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/21.jpg"),
                src: require("../../assets/images/portfolio/21.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/22.jpg"),
                src: require("../../assets/images/portfolio/22.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/23.jpg"),
                src: require("../../assets/images/portfolio/23.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/24.jpg"),
                src: require("../../assets/images/portfolio/24.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/26.jpg"),
                src: require("../../assets/images/portfolio/26.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/27.jpg"),
                src: require("../../assets/images/portfolio/27.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/28.jpg"),
                src: require("../../assets/images/portfolio/28.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/30.jpg"),
                src: require("../../assets/images/portfolio/30.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/31.jpg"),
                src: require("../../assets/images/portfolio/31.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/32.jpg"),
                src: require("../../assets/images/portfolio/32.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/33.jpg"),
                src: require("../../assets/images/portfolio/33.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/34.jpg"),
                src: require("../../assets/images/portfolio/34.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/35.jpg"),
                src: require("../../assets/images/portfolio/35.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/36.jpg"),
                src: require("../../assets/images/portfolio/36.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/37.jpg"),
                src: require("../../assets/images/portfolio/37.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/38.jpg"),
                src: require("../../assets/images/portfolio/38.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/39.jpg"),
                src: require("../../assets/images/portfolio/39.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/1.jpg"),
                src: require("../../assets/images/portfolio/1.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/2.jpg"),
                src: require("../../assets/images/portfolio/2.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/3.jpg"),
                src: require("../../assets/images/portfolio/3.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/40.jpg"),
                src: require("../../assets/images/portfolio/40.jpg"),
                tag: "Wood",
              },
              {
                thumb: require("../../assets/images/portfolio/41.jpg"),
                src: require("../../assets/images/portfolio/41.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/42.jpg"),
                src: require("../../assets/images/portfolio/42.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/43.jpg"),
                src: require("../../assets/images/portfolio/43.jpg"),
                tag: "Vinyl",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                thumb: require("../../assets/images/portfolio/7.jpg"),
                src: require("../../assets/images/portfolio/7.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/11.jpg"),
                src: require("../../assets/images/portfolio/11.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/12.jpg"),
                src: require("../../assets/images/portfolio/12.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/17.jpg"),
                src: require("../../assets/images/portfolio/17.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/22.jpg"),
                src: require("../../assets/images/portfolio/22.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/23.jpg"),
                src: require("../../assets/images/portfolio/23.jpg"),
                tag: "Shower",
              },
              {
                thumb: require("../../assets/images/portfolio/35.jpg"),
                src: require("../../assets/images/portfolio/35.jpg"),
                tag: "Shower",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                thumb: require("../../assets/images/portfolio/4.jpg"),
                src: require("../../assets/images/portfolio/4.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/6.jpg"),
                src: require("../../assets/images/portfolio/6.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/8.jpg"),
                src: require("../../assets/images/portfolio/8.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/9.jpg"),
                src: require("../../assets/images/portfolio/9.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/10.jpg"),
                src: require("../../assets/images/portfolio/10.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/13.jpg"),
                src: require("../../assets/images/portfolio/13.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/14.jpg"),
                src: require("../../assets/images/portfolio/14.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/16.jpg"),
                src: require("../../assets/images/portfolio/16.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/18.jpg"),
                src: require("../../assets/images/portfolio/18.jpg"),
                tag: "Tile + Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/29.jpg"),
                src: require("../../assets/images/portfolio/29.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/21.jpg"),
                src: require("../../assets/images/portfolio/21.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/24.jpg"),
                src: require("../../assets/images/portfolio/24.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/26.jpg"),
                src: require("../../assets/images/portfolio/26.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/31.jpg"),
                src: require("../../assets/images/portfolio/31.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/34.jpg"),
                src: require("../../assets/images/portfolio/34.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/36.jpg"),
                src: require("../../assets/images/portfolio/36.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/37.jpg"),
                src: require("../../assets/images/portfolio/37.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/38.jpg"),
                src: require("../../assets/images/portfolio/38.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/39.jpg"),
                src: require("../../assets/images/portfolio/39.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/1.jpg"),
                src: require("../../assets/images/portfolio/1.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/2.jpg"),
                src: require("../../assets/images/portfolio/2.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/3.jpg"),
                src: require("../../assets/images/portfolio/3.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/41.jpg"),
                src: require("../../assets/images/portfolio/41.jpg"),
                tag: "Tile",
              },
              {
                thumb: require("../../assets/images/portfolio/42.jpg"),
                src: require("../../assets/images/portfolio/42.jpg"),
                tag: "Tile",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                thumb: require("../../assets/images/portfolio/27.jpg"),
                src: require("../../assets/images/portfolio/27.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/28.jpg"),
                src: require("../../assets/images/portfolio/28.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/30.jpg"),
                src: require("../../assets/images/portfolio/30.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/33.jpg"),
                src: require("../../assets/images/portfolio/33.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/43.jpg"),
                src: require("../../assets/images/portfolio/43.jpg"),
                tag: "Vinyl",
              },
              {
                thumb: require("../../assets/images/portfolio/25.jpg"),
                src: require("../../assets/images/portfolio/25.jpg"),
                tag: "Vinyl",
              },              
              {
                thumb: require("../../assets/images/portfolio/44.jpg"),
                src: require("../../assets/images/portfolio/44.jpg"),
                tag: "Vinyl",
              },
            ],
          },
          {
            id: 5,
            content: [
              {
                thumb: require("../../assets/images/portfolio/5.jpg"),
                src: require("../../assets/images/portfolio/5.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/15.jpg"),
                src: require("../../assets/images/portfolio/15.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/18.jpg"),
                src: require("../../assets/images/portfolio/18.jpg"),
                tag: "Tile + Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/19.jpg"),
                src: require("../../assets/images/portfolio/19.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/20.jpg"),
                src: require("../../assets/images/portfolio/20.jpg"),
                tag: "Backsplash",
              },
              {
                thumb: require("../../assets/images/portfolio/32.jpg"),
                src: require("../../assets/images/portfolio/32.jpg"),
                tag: "Backsplash",
              },
            ]
          }
        ],
      };
    },
  };
</script>
