var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--35",attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"section-title text-left mb--50 mb_sm--30 mb_md--30"},[_c('h2',{staticClass:"heading-title"},[_vm._v("Get in Touch")]),_c('p',{staticClass:"description"},[_vm._v(" If you want to get a quote or just ask us a question, feel free to text us or fill out the form below. ")])]),_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"rn-address mb--30 mb_sm--0 mb_md--0"},[_c('div',{staticClass:"inner"},[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-phone"})])]),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',{staticClass:"phone-number"},[_c('a',{attrs:{"href":"tel:+9419148487"}},[_vm._v("(941) 914-8487")])])])],1)],1)])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"rn-address mb--30"},[_c('div',{staticClass:"inner"},[_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-phone"})])]),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',{staticClass:"phone-number"},[_c('a',{attrs:{"href":"tel:+19412765377"}},[_vm._v("(941) 276-5377")])])])],1)],1)])])],1),(!_vm.submitted && !_vm.submitError)?_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Your Name *"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Your email *"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subject),expression:"formData.subject"}],attrs:{"type":"text","placeholder":"Write a Subject"},domProps:{"value":(_vm.formData.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subject", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"Your Message"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"rn-button-style--2 btn_solid",attrs:{"type":"submit","value":"submit"}},[_vm._v(" Submit ")])],1)]}}],null,false,3651029562)})],1):(_vm.submitted && !_vm.submitError)?_c('div',[_vm._v(" Thank you for your message! We'll be in touch soon! ")]):_c('div',[_vm._v(" Error sending your message. Please try again later or email us directly at "),_c('a',{attrs:{"href":"mailto:vladimirleonov68@gmail.com"}},[_vm._v("vladimirleonov68@gmail.com")]),_vm._v("! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }